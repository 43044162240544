import React, { useState, useEffect, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './components/Header';
import SideMenu from './components/SideMenu';
import Footer from './components/Footer';
import Login from './components/Login';
import SectionHeader from './components/SectionHeader';
import HorizontalLine from './components/HorizontalLine';
import HorizontalLine2 from './components/horizontalLine2';
import { isAuthenticated, fetchUserAndPartnerInfo } from './api';
import Banner from './components/Banner';
import UserGreeting from './components/UserGreetings';
import IconContainer from './components/IconContainer';
import GlobalLoader from './utils/GlobalLoader';

const HotelUserGreeting = React.lazy(() => import('./components/HotelUserGreeting'));
const HotelBanner = React.lazy(() => import('./components/HotelBanner'));
const HotelBeschreibung = React.lazy(() => import('./components/HotelBeschreibung'));
const HotelUmfrage = React.lazy(() => import('./components/HotelUmfrage'));
const HotelEndeKlick = React.lazy(() => import('./components/HotelEndeKlick'));
const Greeting = React.lazy(() => import('./components/Greeting'));
const Team = React.lazy(() => import('./components/Team'));
const Einladung = React.lazy(() => import('./components/Einladung'));
const Einladung2 = React.lazy(() => import('./components/Einladung2'));
const RuckmeldungVote = React.lazy(() => import('./components/RuckmeldungVote'));
const RuckmeldungErgebnis = React.lazy(() => import('./components/RuckmeldungErgebnis'));
const LocationUserGreeting = React.lazy(() => import('./components/LocationUserGreeting'));
const LocationBanner = React.lazy(() => import('./components/LocationBanner'));
const MenuVote = React.lazy(() => import('./components/MenuVote'));
const MenuErgebnis = React.lazy(() => import('./components/MenuErgebnis'));
const Ablauf = React.lazy(() => import('./components/Ablauf'));
const Ablauf2 = React.lazy(() => import('./components/Ablauf2'));
const FotoUpload = React.lazy(() => import('./components/FotoUpload'));
const FotoDiashow = React.lazy(() => import('./components/FotoDiashow'));
const Dresscode = React.lazy(() => import('./components/Dresscode'));
const Geschenk = React.lazy(() => import('./components/Geschenk'));
const Musik = React.lazy(() => import('./components/Musik'));


const App = () => {
  const [isAuth, setIsAuth] = useState(null);
  const [loading, setLoading] = useState(false); 
  const [selectedComponent, setSelectedComponent] = useState('home');
  const [user, setUser] = useState(null); 
  const [anrede, setAnrede] = useState(''); 
  const [attendance, setAttendance] = useState(''); 
  const [essen, setEssen] = useState(''); 
  const [paar, setPaar] = useState(''); 

  

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('jwt');
      if (!token || !isAuthenticated()) {
        setIsAuth(false);
        navigate('/login'); 
      } else {
        setIsAuth(true);
        try {
          const { user, anrede } = await fetchUserAndPartnerInfo(token);
          setUser(user);
          setAnrede(anrede);
          setAttendance(user.zusage); 
          setEssen(user.essen)
          setPaar(user.paar); 
          console.log(user.paar)
        } catch (error) {
          console.error('Error fetching user info:', error);
        }
      }
    };
    checkAuth();
  }, [navigate]);

    useEffect(() => {
      window.scrollTo(0, 1);
      window.scrollTo(0, 0);
    }, []);

  const handleIconClick = async (componentKey) => {
    setLoading(true); // Ladeanimation aktivieren
    await new Promise(resolve => setTimeout(resolve, 400)); // Simuliert eine Ladezeit
    setSelectedComponent(componentKey);
    setLoading(false); // Ladeanimation deaktivieren
  };

  const componentsMap = {
    einladung: (
      <>
        <SectionHeader title="Einladung" />
        <Einladung anrede={anrede} paar={paar} /> {/* paar wird hier übergeben */}
        <HorizontalLine />
        <Einladung2 handleIconClick={handleIconClick} paar={paar}/>
      </>
    ),
    ruckmeldung: (
      <>
        <SectionHeader title="Rückmeldung" />
        <RuckmeldungVote attendance={attendance} />
        <HorizontalLine />
        <RuckmeldungErgebnis handleIconClick={handleIconClick} attendance={attendance} />
      </>
    ),
    hotel: (
      <>
        <SectionHeader title="Hotel" />
        <HotelUserGreeting anrede={anrede} paar={paar} />
        <HotelBanner paar={paar} />
        <HorizontalLine />
        <HotelBeschreibung paar={paar} />
        <SectionHeader title="Reservierung" />
        <HotelUmfrage title="Reservierung" hotel={user?.hotel} />
        <HotelEndeKlick handleIconClick={handleIconClick} hotel={user?.hotel} />
      </>
    ),
    location: (
      <>
        <SectionHeader title="Location" />
        <LocationUserGreeting anrede={anrede} paar={paar} />
        <HorizontalLine />
        <LocationBanner handleIconClick={handleIconClick} />
      </>
    ),
    menu: (
      <>
        <SectionHeader title="Menü" />
        <MenuVote essen={essen} />
        <HorizontalLine />
        <MenuErgebnis handleIconClick={handleIconClick} essen={essen} />

      </>
    ),
    ablauf: (
      <>
        <SectionHeader title="Ablauf" />
        <Ablauf />
        <HorizontalLine />
        <Ablauf2 handleIconClick={handleIconClick}/>
      </>
    ),
    fotos: (
      <>
        <SectionHeader title="Fotobereich" />
        <FotoUpload userId={user?.id} /> 
        <HorizontalLine />
        <FotoDiashow userId={user?.id} />
        <Ablauf2 handleIconClick={handleIconClick} />
      </>
    ),
    dresscode: (
      <>
        <SectionHeader title="Dresscode" />
        <Dresscode paar={paar} anrede={anrede}/>
        <HorizontalLine />
        <Ablauf2 handleIconClick={handleIconClick} />
      </>
    ),
    geschenk: (
      <>
        <SectionHeader title="Geschenke" />
        <Geschenk paar={paar} anrede={anrede}/>
        <HorizontalLine />
        <Ablauf2 handleIconClick={handleIconClick} />
      </>
    ),
    musik: (
      <>
        <SectionHeader title="Musik" />
        <Musik userId={user?.id} />
        <HorizontalLine />
        <Ablauf2 handleIconClick={handleIconClick} />
      </>
    ),
    home: (
      <>
        <Banner />
        <HorizontalLine />
        <UserGreeting anrede={anrede} paar={paar}/>
        <IconContainer onIconClick={handleIconClick} />
        <Greeting paar={paar}/>
        <HorizontalLine2 />
        <Team />
      </>
    ),
  };

  const renderSelectedComponent = () => componentsMap[selectedComponent] || componentsMap['home'];

  if (isAuth === null) {
    return <div>Loading...</div>;
  }

  return isAuth ? (
    <div className="App">
      {loading && <GlobalLoader />} {/* Ladeanimation anzeigen, wenn loading true ist */}
      <Header selectedComponent={selectedComponent} handleIconClick={handleIconClick} />
      <SideMenu onIconClick={handleIconClick} />
      <Suspense fallback={<GlobalLoader />}>
        {renderSelectedComponent()}
      </Suspense>
      <Footer />
    </div>
  ) : (
    <Login />
  );
};

export default App;
