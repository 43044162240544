import { jwtDecode } from 'jwt-decode'; // Richtiger Import von jwtDecode

export const fetchWithErrorHandling = async (url, options) => {
  const baseUrl = process.env.REACT_APP_API_URL || 'https://reinhardtshochzeit.de';

  try {
    const response = await fetch(`${baseUrl}${url}`, options);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
};

export const isAuthenticated = () => {
  const token = localStorage.getItem('jwt');
  return token !== null;
};

export const fetchUserAndPartnerInfo = async (token) => {
  try {
    const decodedToken = jwtDecode(token);
    const userId = decodedToken.user_id;

    // Hole die User-Informationen von der neuen API
    const user = await fetchWithErrorHandling(`/api/getUserInfo/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });

    let partner = null;
    let anrede = '';

    // Wenn der User ein Paar hat, hole die Partner-Informationen
    if (user.paar) {
      partner = await fetchWithErrorHandling(`/api/getPartnerInfo?paarid=${user.paarid}&geschlecht=${user.geschlecht}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });

      // Erstelle die Anrede basierend auf dem Geschlecht des Partners
      const partnerAnrede = partner.geschlecht === 'mann' ? `lieber ${partner.vorname},` : `liebe ${partner.vorname},`;
      anrede = `${user.geschlecht === 'mann' ? `Lieber ${user.vorname}` : `Liebe ${user.vorname}`}, ${partnerAnrede}`;
    } else {
      // Erstelle die Anrede, wenn es keinen Partner gibt
      anrede = user.geschlecht === 'mann' ? `Lieber ${user.vorname}` : `Liebe ${user.vorname}`;
    }
    console.log("user:",user)
    return { user, partner, anrede };
    
  } catch (error) {
    console.error('Error fetching user info:', error);
    throw error;
  }
};


export const uploadPhoto = async (file, userId, isPrivate) => {
  const baseUrl = process.env.REACT_APP_API_URL || 'https://reinhardtshochzeit.de';
  const formData = new FormData();
  formData.append('photo', file);
  formData.append('userId', userId);
  formData.append('privat', isPrivate);

  try {
    const response = await fetch(`${baseUrl}/api/uploadPhoto`, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`Upload error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Upload error:', error);
    throw error;
  }
};

