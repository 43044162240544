import React, { useEffect } from 'react';
import '../assets/styles/SideMenu.css';

const SideMenu = ({ onIconClick }) => {
  const menuItems = [
    { key: "startseite", text: "Startseite" },
    { key: "hotel", text: "Hotel" },
    { key: "einladung", text: "Einladung" },
    { key: "ablauf", text: "Ablauf" },
    { key: "fotos", text: "Foto-Upload" },
    { key: "ruckmeldung", text: "Rückmeldung" },
    { key: "dresscode", text: "Dress-Code" },
    { key: "location", text: "Location" },
    { key: "menu", text: "Menü" }
  ];

  const smoothScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleMenuItemClick = (key) => {
    console.log("Clicked menu item:", key);  // Hier wird das Key geloggt
    smoothScrollToTop(); 
    onIconClick(key);
    closeMenu(); // Menü schließen
  };

  const closeMenu = () => {
    const menu = document.getElementById('side-menu');
    if (menu) {
      menu.classList.remove('visible');
    }
  };

  const toggleMenu = () => {
    const menu = document.getElementById('side-menu');
    if (menu) {
      menu.classList.toggle('visible');
    }
  };

  const handleOutsideClick = (event) => {
    const menu = document.getElementById('side-menu');
    const menuButton = document.querySelector('.hamburger-menu');
    if (menu && !menu.contains(event.target) && !menuButton.contains(event.target)) {
      closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className="side-menu" id="side-menu">
      {menuItems.map((item, index) => (
        <div 
          key={index} 
          className="menu-item"
          onClick={() => handleMenuItemClick(item.key)}
        >
          {item.text}
        </div>
      ))}
    </div>
  );
};

export default SideMenu;
