import React from 'react';
import '../assets/styles/HorizontalLines.css';


const HorizontalLine2 = () => {
  return ( 
    <div className="horizontal-line">
  </div>
  );
}

export default HorizontalLine2;
