// GlobalLoader.js
import React from 'react';
import './../assets/styles/GlobalLoader.css';

const GlobalLoader = () => (
  <div className="global-loader">
    <div className="loader-icon"></div>
    <p>Laden...</p>
  </div>
);

export default GlobalLoader;
