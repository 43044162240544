import React from 'react';
import '../assets/styles/IconContainer.css';

const IconContainer = ({ onIconClick }) => {
  const icons = [
    { key: "einladung", imgSrc: "img/icons/brief.svg", title: "Einladung", text: "Hiermit machen wir es offiziell." },
    { key: "ruckmeldung", imgSrc: "img/icons/daumen.svg", title: "Rückmeldung", text: "Wir bitten um Rückmeldung." },
    { key: "hotel", imgSrc: "img/icons/bett.svg", title: "Hotel-Infos", text: "Wir übernehmen die Hotel-Reservierung" },
    { key: "location", imgSrc: "img/icons/maps.svg", title: "Location", text: "Alle Infos zur Location." },
    { key: "menu", imgSrc: "img/icons/wasessen.svg", title: "Menü", text: "Was sollen wir berücksichtigen?" },
    { key: "ablauf", imgSrc: "img/icons/feier.svg", title: "Ablauf", text: "Alle Infos zur Feier." },
    { key: "fotos", imgSrc: "img/icons/fotos.svg", title: "Fotos", text: "Hier können Fotos hochgeladen werden" },
    { key: "dresscode", imgSrc: "img/icons/kleid.svg", title: "Dresscode", text: "Do's & Dont's." },
    { key: "musik", imgSrc: "img/icons/musik.svg", title: "Musik", text: "Was darf nicht fehlen" },
    { key: "geschenk", imgSrc: "img/icons/geschenk.svg", title: "Geschenke", text: "Was wir uns wünschen" }
  ];

  const smoothScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Verwendet das native Smooth-Scrolling von JavaScript
    });
  };

  const handleIconClick = (key) => {
    smoothScrollToTop(); // Scrollt nach oben
    onIconClick(key); // Wechselt die Komponente
  };

  return (
    <div className="icon-container">
      {icons.map((icon, index) => (
        <div 
          className="icon" 
          key={index} 
          onClick={() => handleIconClick(icon.key)}
        >
          <img src={icon.imgSrc} alt={icon.title} />
          <h2>{icon.title}</h2>
          <p>{icon.text}</p>
        </div>
      ))}
    </div>
  );
};

export default IconContainer;
