import React from 'react';
import '../assets/styles/HorizontalLines.css'; 

const SectionHeader = ({ title }) => {
  return (
    <div className="SectionLine">
      <span className="Line-Symbol">{title}</span>
    </div>
  );
};

export default SectionHeader;
