import React, { useEffect, useRef } from 'react';
import '../assets/styles/App.css';
import '../assets/styles/Banner.css';


const Banner = () => {
  const slideIndex = useRef(0);
  const slidesRef = useRef([]);

  useEffect(() => {
    const showSlide = (n) => {
      if (!slidesRef.current.length) return;

      if (n >= slidesRef.current.length) slideIndex.current = 0;
      if (n < 0) slideIndex.current = slidesRef.current.length - 1;

      slidesRef.current.forEach(slide => slide.style.display = 'none');
      slidesRef.current[slideIndex.current].style.display = 'block';
    };

    const changeSlide = (n) => {
      showSlide(slideIndex.current += n);
    };

    showSlide(slideIndex.current);

    const interval = setInterval(() => {
      changeSlide(1);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="banner">
      {['/img/landingpage/banner/DSC_0013.jpg', '/img/landingpage/banner/DSC_0774.jpg', '/img/landingpage/banner/DSC_0780.jpg', '/img/landingpage/banner/IMG_0851.jpg', '/img/landingpage/banner/DSC_0063.jpg'].map((src, index) => (
        <div className="slide" key={index} ref={el => slidesRef.current[index] = el}>
          <img src={src} alt={`Image ${index + 1}`} />
        </div>
      ))}
    </div>
  );
};

export default Banner;
