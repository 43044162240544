import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { fetchWithErrorHandling } from '../api';
import '../assets/styles/Login.css';
import Footer from './Footer';

const Login = () => {
  const [handynummer, setHandynummer] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // Neue State-Variable für Fehlermeldung
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetchWithErrorHandling('/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ handynummer, password }),
      });

      if (response.token) {
        localStorage.setItem('jwt', response.token);
        const decodedToken = jwtDecode(response.token);
        localStorage.setItem('user_id', decodedToken.user_id);
        navigate('/dashboard');
      } else {
        setErrorMessage('Login fehlgeschlagen: Bitte überprüfen Sie Ihre Handynummer und Ihr Passwort.'); // Fehlermeldung setzen
      }
    } catch (error) {
      console.error('Fehler beim Login:', error);
      setErrorMessage('Login fehlgeschlagen: Bitte überprüfen Sie Ihre Handynummer und Ihr Passwort.'); // Allgemeine Fehlermeldung setzen
    }
  };

  return (
    <div>
      <div className='login-background'>
        <form className='Loginform' onSubmit={handleSubmit}>
          <h2>Login</h2>
          <input className='LoginformInput'
            type="text"
            name="handynummer"
            placeholder="Handynummer"
            value={handynummer}
            onChange={(e) => setHandynummer(e.target.value)}
            required
          />
          <input className='LoginformInput'
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button className="btn" type="submit">Login</button>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
