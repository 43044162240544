import React from 'react';
import '../assets/styles/Header.css';

const Header = ({ selectedComponent, handleIconClick }) => {
  const toggleMenu = () => {
    const menu = document.getElementById('side-menu');
    if (menu) {
      menu.classList.toggle('visible');
    }
  };

  const logout = () => {
    localStorage.removeItem('jwt');
    window.location.href = 'index.html';
  };

  const isHome = selectedComponent === 'home';

  return (
    <div className="headerDanielundSophia">
      <div className="headerDanielundSophiaStructure">
        <div className="hamburger-menu" onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="center-container">
          <h1 className="centered">
            <span className="daniel">Daniel</span>
            <span className="ampersand">&</span>
            <span className="sophia">Sophia</span>
          </h1>
        </div>
        <button 
          className="logout-button" 
          onClick={isHome ? logout : () => handleIconClick('home')}
        >
          {isHome ? 'Logout' : 'Zurück'}
        </button>
      </div>
    </div>
  );
};

export default Header;
