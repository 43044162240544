import React from 'react';
import '../assets/styles/HorizontalLines.css';


const HorizontalLine = () => {
  return ( 
    <div className="center-line">
    <span className="line-symbol">∞</span>
  </div>
  );
}

export default HorizontalLine;
