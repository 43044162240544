import React from 'react';
import '../assets/styles/Greeting.css';

const UserGreeting = ({ anrede, paar }) => {
  return (
    <div className='greeting-container'>
      <div className='greeting-text'>

        {paar ? (
          <>
            <h3 id="user_anrede">{anrede}</h3>
            <p>
              Wir freuen uns sehr darauf, unsere Hochzeit mit euch zu feiern! Diese WebApp wollen wir nutzen, um euch alle Infos und Updates rund um die Feier zu geben. Ihr findet hier:
            </p>
          </>
        ) : (
          <>
          <h3 id="user_anrede">{anrede},</h3>
            <p>
              Wir freuen uns sehr darauf, unsere Hochzeit mit dir zu feiern! Diese WebApp wollen wir nutzen, um dir alle Infos und Updates rund um die Feier zu geben. Du findest hier:
            </p>
          </>
        )}
        <ul>
          <li>Die Einladung</li>
          <li>Den Ablauf des Tages</li>
          <li>Infos rund ums Hotel</li>
          <li>Die Möglichkeit, uns Musikwünsche zu schicken</li>
          <li>Die Anfahrtsbeschreibung</li>
          <li>Infos zu Übernachtungsmöglichkeiten</li>
          <li>Und vieles mehr...</li>
        </ul>
      </div>
    </div>
  );
};

export default UserGreeting;
