// src/components/Footer.js
import React from 'react';
import '../assets/styles/Footer.css';


const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-structure">
        <p>Hof von Laer</p>
        <p>Daniel & Sophia</p>
        <p>14.09.2024</p>
      </div>
    </div>
  );
};

export default Footer;
